.faculty-img {
  border-radius: 100%;
  height: 15rem;
  width: 15rem;
  border: 2px solid;
}

.info-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 100rem;
}

.width-strainer {
  width: 7rem !important;
  height: 7rem !important;
}

.padding {
  margin-top: 1.5rem;
}

.padding-2 {
  padding: 0.2rem !important;
}

.row-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
