/* .MTech {
  background-color: #b4d1ff;
} */

.mtech-container {
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.mtech-item {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
  margin: 10px;
  margin-bottom: 50px;
}

h1 {
  font-size: 24px;
}

p {
  font-size: 16px;
  color: #555;
}

.course-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
}

.course-item {
  transition: all 0.3s linear;
}

.course-card:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25), 0px 5px 7px rgba(0, 0, 0, 0.35);
}

.img-container {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.course-img {
  transition: all 1s ease-in-out;
}

.img-container:hover .course-img {
  transform: scale(1.2);
}

.course-item-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.75rem;
  background: #ffc304;
  font-size: 1.2rem;
  padding-bottom: 0.5rem;

  border-top-left-radius: 1rem;
  transition: all 1s ease-in-out;
  transform: translate(100%, 100%);
}

.img-container:hover .course-item-icon {
  transform: translate(0, 0);
}
