.outer-card {
  border: 2px solid #d7d6d6 !important;
  padding-left: 24px;
  padding-right: 24px;
}

.year-title {
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  margin-top: 16px;
}

.internship-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* .topper-table { */
/*     border-style: solid; */
/*     border-width: 2px; */
/* } */
/**/
/* .tooper-table th { */
/*     border-style: solid; */
/*     border-width: 2px; */
/* } */
/**/
/* .tooper-table tr { */
/*     border-style: solid; */
/*     border-width: 2px; */
/* } */

.topper-photu {
  width: 10rem;
  border-radius: 100%;
  padding-right: 1rem;
  align-items: start;
}

.student-details {
  display: flex;
  justify-content: space-evenly;
}

.category {
  color: maroon;
  /*font-size: 25px !important;*/
}

.internship-stats-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.internship-stats-container > div {
  flex: 1;
}

.student-internship-table {
  max-width: 400px; /* Adjust as needed */
}

td {
  font-size: 1.2rem !important;
}

h1,
h2,
h3 {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.justify-content-center {
  justify-content: center;
}

.student-internship-table {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid #002b56;
}

.student-internship-table th,
.student-internship-table td {
  text-align: center;
}

.student-internship-table thead th {
  background-color: #002b56;
  color: #fff;
}

.student-internship-table tbody tr:nth-child(even) {
  background-color: #f8f9fa;
}
