/* .publications {
  background-color: #b4d1ff;
} */
iframe::-webkit-scrollbar {
  display: none;
}

#docTitle{
  display: none;
}

#switcherOuter {
  display: none;
 }

.course-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
}

.course-item {
  transition: all 0.3s linear;
}

.course-card:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25), 0px 5px 7px rgba(0, 0, 0, 0.35);
}

.img-container {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.course-img {
  transition: all 1s ease-in-out;
}

.img-container:hover .course-img {
  transform: scale(1.2);
}

.course-item-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.75rem;
  background: #ffc304;
  font-size: 1.2rem;
  padding-bottom: 0.5rem;

  border-top-left-radius: 1rem;
  transition: all 1s ease-in-out;
  transform: translate(100%, 100%);
}

.img-container:hover .course-item-icon {
  transform: translate(0, 0);
}

.custom-card {
  width: 100%;
  height: auto;
}

.custom-card-img {
  width: 100%;
  height: auto;
  object-fit: fill;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  border: 2px solid black;
  /* Adjust the thickness as needed */
  padding: 8px;
  text-align: left;
}

.custom-table th {
  background-color: #f2f2f2;
}