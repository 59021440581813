@media (max-width: 500px) {
  .main-row {
    margin: 0 !important;
  }
}

.message-card:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25), 0px 5px 7px rgba(0, 0, 0, 0.35);
}

.latest-news {
  font-size: 1.1rem;
  margin-top: 10px;
}

.latest-news i {
  color: darkorange;
}

.marq-a {
  color: black;
}

.marq-a:hover {
  color: red;
}
