@media (max-width: 770px) {
    .about-text {
        width: 100% !important;
    }
}

.card-style {
    /* background-color: red; */
    border-radius: 10px;
    border: 2px solid blue;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin-top: 0px;
    margin-bottom: 50px;
    width: 90%,
}

.card-style h1 {
    color: #333;
}

.card-style p,
.card-style ul {
    color: #555;
}

.card-style ul {
    padding-left: 20px;
}

video {
    max-width: 67rem;
    height: 550px;
    object-fit: contain;
}

iframe {
    max-width: auto;
    height: 550px;
    object-fit: contain;
}

@media (max-width: 950px) {
    video {
        height: 450px;
    }

    iframe {
        height: 450px;
    }

    .video-container {
        height: 450px;
    }
}

@media (max-width: 770px) {
    video {
        height: 350px;
    }

    iframe {
        height: 350px;
    }

    .video-container {
        height: 350px;
    }
}

@media (max-width: 450px) {
    video {
        height: 100px;
    }

    iframe {
        height: 100px;
    }

    .video-container {
        height: 100px;
    }
}

.video-container {
    padding: 0 !important;
    overflow: hidden;
    margin: 0 !important;
}

.video-row {
    margin: 0 !important;
    position: relative;
}

.video-col {
    margin: 0 !important;
    padding: 0 !important;
}

.video-text {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 62%;
    font-size: 2.5rem;
    color: white;
    text-transform: capitalize;
    font-weight: bolder;
    transform: translate(-50%, -50%);

    text-align: center;
}

h2 {
    font-size: medium;
    cursor: none;
    font-weight: bold;
}

@media (max-width: 500px) {
    .video-text {
        font-size: 2.1rem;
    }

    iframe {
        height: 250px;
    }

    .video-container {
        height: 250px;
    }
}