h1 {
  text-align: center;
  margin-top: 50px;
}

.spreadImg {
  height: 15%;
  margin-left: -150px;
  max-width: 150%;
}

.recruiterList {
  border: 1px solid #dddddd;
  padding: 10px;
  border-radius: 5px;
  min-width: 900px;
  margin-left: -100px !important;
}

.recruiterItem {
  border: 1px solid #dddddd;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 5px;
}

/* Media query to adjust styles for smaller screens */
@media (max-width: 768px) {
  .recruiterItem {
    padding: 8px;
  }
}

.flex-center-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  justify-items: center;
}

.flex-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
}

.img-fluid-border {
  border: 1px solid black !important;
}
